@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  /* libre-franklin-latin-wght-normal */
  @font-face {
    font-family: 'Libre Franklin Variable';
    font-style: normal;
    font-display: swap;
    font-weight: 100 900;
    src: url(@fontsource-variable/libre-franklin/files/libre-franklin-latin-wght-normal.woff2) format('woff2-variations');
    unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  }
  /* roboto-latin-100-normal */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 100;
    src: url(@fontsource/roboto/files/roboto-latin-100-normal.woff2) format('woff2'), url(@fontsource/roboto/files/roboto-latin-100-normal.woff) format('woff');
    unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  }

  /* roboto-latin-300-normal */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url(@fontsource/roboto/files/roboto-latin-300-normal.woff2) format('woff2'), url(@fontsource/roboto/files/roboto-latin-300-normal.woff) format('woff');
    unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  }

  /* roboto-latin-400-normal */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(@fontsource/roboto/files/roboto-latin-400-normal.woff2) format('woff2'), url(@fontsource/roboto/files/roboto-latin-400-normal.woff) format('woff');
    unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  }

  /* roboto-latin-500-normal */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(@fontsource/roboto/files/roboto-latin-500-normal.woff2) format('woff2'), url(@fontsource/roboto/files/roboto-latin-500-normal.woff) format('woff');
    unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  }

  /* roboto-latin-700-normal */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(@fontsource/roboto/files/roboto-latin-700-normal.woff2) format('woff2'), url(@fontsource/roboto/files/roboto-latin-700-normal.woff) format('woff');
    unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  }

  /* roboto-latin-900-normal */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: url(@fontsource/roboto/files/roboto-latin-900-normal.woff2) format('woff2'), url(@fontsource/roboto/files/roboto-latin-900-normal.woff) format('woff');
    unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  all: unset;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

button {
  all: unset;
}

#terms ol,ul {
  list-style-type: disc;
}

.contenedor{
  margin-top: 6rem;
}


/* Card.css */
.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7faff;
  border-radius: 25px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  width: 23rem;
  height: 14rem;
  margin-top: -5.7rem !important;
}

.distance-badge {
  position: absolute;
  top: 16px;
  left: 2rem;
  background-color: #FCCC0A;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  border-radius: 16px;
  padding: 4px 8px;
}

.card-content {
  flex-grow: 1;
  margin-top:2rem
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title h2 {
  font-size: 18px;
  color: #070561;
  margin: 0;
}

.heart-button {
  background: none;
  border: none;
  cursor: pointer;
}

.address {
  font-size: 14px;
  color: #76A8FF;
  margin: 4px 0;
}

.subtext {
  font-size: 14px;
  color: #070561;
}

.discounts {
  font-size: 14px;
  color: #1D9D74;
  font-weight: bold;
  margin-top:1rem;
}

.arrow-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #000;
  cursor: pointer;
}


/* LocationComponent.css */
.location-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 55px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #f9f9ff;
  text-align: left;
  font-family: Arial, sans-serif;
  color: #1a1a1a;
}

.location-container h2 {
  font-size: 18px;
  color: #070561;
  margin-bottom: 20px;
}

.location-message {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: #070561;
}

.message-error-store{
  font-family: Roboto;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
color: #070561;
}

.icon {
  font-size: 24px;
  margin-right: 10px;
  color: #fbb034;
  margin-bottom: 2rem;
}

.authorize-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  font-size: 16px;
  color: #fff;
  background-color: #100E9D;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s;
  margin-left: 1rem;
}

.authorize-button:hover {
  background-color: #0e0e4c;
}

.authorize-button span {
  margin-right: 8px;
}


/* Details */

h1 {
  font-size: 1.5rem;
  color: #003366;
}

.store-card {
  background: #F2F2F7;
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 94vh;
}

.store-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.distance {
  border-radius: 6px;
  background-color: #FCCC0A;
  color: #141938;
  padding-left: 3px;
  width: 55px;
  font-size: 11px;
  font-weight: 700;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

.store-name {
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; 
  color: #070561;
  text-align: left; 
  margin-left: 1rem;
}

.store-address,
.store-discounts {
  margin: 0;
  font-size: 0.9rem;
  color: #76A8FF;
  text-align: left; 
  margin-left: 1rem;
  
}

.store-discounts {
  color: #1D9D74;
  font-weight: bold;
  margin-bottom: 0.1rem;
}

.discounts {
  display: grid;
  gap: 1rem;
}

.discount-card {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px dashed #ccc;
}

.discount-image {
  width: 150px;
  height: auto;
}

.discount-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.discount-title {
  font-size: 1rem;
  color: #003366;
  margin: 0;
}

.discount-description {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.discount-price {
  font-size: 1rem;
  font-weight: bold;
  color: #000;
}

.discount-button {
  background: #d4edda;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  color: #155724;
  font-weight: bold;
  cursor: pointer;
  font-size: 0.9rem;
}

.page {

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
} 


.arrow-icon {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 10rem;
}

.heart-icon {
  color: #ff4081;
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
  display: block;
  margin-bottom: -1rem;
}

.favorite-button{
  width: 3rem;
  font-size: 30px;
  margin-top: -12px;

}


.favorites-icon {
  width: 3rem;
  font-size: 30px;
  margin-top: -12px;
}

.favorite-button {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e5e7eb; /* Gray-200 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.favorite-button.favorite {
  background: linear-gradient(to right, #ec4899, #8b5cf6); /* Pink to Purple */
}

.icon {
  width: 50px;
  height: 50px;
  color: #9ca3af; /* Gray-400 */
  transition: all 0.3s ease-in-out;
}

.favorite-button.favorite .icon {
  color: white;
}

.space{
  padding-bottom: 7rem
}

.favorites-icon-detail {
  width: 3rem;
  position: absolute;
  right: 1rem; /* Ajusta la posición desde el borde derecho */
  width: 3rem;
  font-size: 30px;
  cursor: pointer; /* Cambia el cursor para indicar interactividad */
}

.space-back{
  margin: 0.9rem;
}

#walktour-tooltip-container:focus-visible{
  outline: none;
}

.history-summary-card{
  background: linear-gradient(230deg, #100E9D 0%, #375B98 100%);
}

.fill-available{
  width: -webkit-fill-available;
}

.summary-card-shadow{
  filter: drop-shadow(0px 1px 5px rgba(9, 30, 66, 0.09)) 
        drop-shadow(0px 0px 2px rgba(9, 30, 66, 0.11));

}
